<template>
    <div class="workbench">
        <!-- 采购需求 -->
        <!-- <vue-seamless-scroll :data="demandList" :class-option="classOption" class="warpBox" v-if="!isYiwu">
            <div class="ul-item" @click="toViewDemand">
                <div class="li-item utils-omit-1" v-for="(item, index) in demandList" :key="index">
                    {{item.title}} 采购量：{{item.purchaseVolume}} {{item.unit}} {{item.describe}}
                </div>
            </div>
        </vue-seamless-scroll> -->

        <div class="leftBox">
            <el-card>
                <div slot="header">
                    <span class="title">数据简报</span>
                    <!-- <span class="timeSlot">2020-12-01 —— 2021-01-01</span> -->
                </div>
                <el-row class="dataPresentation">
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon1.png" alt="" />
                        <div style="display: inline-block;cursor: pointer;" @click="toCustomer">
                            <div class="value" style="color:var(--themeColor);">{{dataObjOne.customerNum}}</div>
                            <div class="label">客户总数</div>
                        </div>
                    </el-col>
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon2.png" alt="" />
                        <div style="display: inline-block;cursor: pointer;" @click="toBusiness">
                            <div class="value" style="color:var(--themeColor);">{{dataObjOne.businessChanceNum}}</div>
                            <div class="label">商机总数</div>
                        </div>
                    </el-col>
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon3.png" alt="" />
                        <div style="display: inline-block;cursor: pointer;" @click="toOrder">
                            <div class="value" style="color:var(--themeColor);">{{dataObjOne.sameMonthOrderNum}}</div>
                            <div class="label">当月订单数</div>
                        </div>
                    </el-col>
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon4.png" alt="" />
                        <div style="display: inline-block;cursor: pointer;" @click="toOrder">
                            <div class="value" style="color:var(--themeColor);">{{dataObjOne.sameMonthOrderAmount}}</div>
                            <div class="label">当月订单金额</div>
                        </div>
                    </el-col>
                </el-row>                    
                <el-row class="dataPresentation" style="margin-top:20px;">
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon3.png" alt="" />
                        <div style="display: inline-block;cursor: pointer;" @click="toPurchaseRecord">
                            <div class="value" style="color:var(--themeColor);">{{dataObjOne.purchaseQuantity}}</div>
                            <div class="label">采购数量</div>
                        </div>
                    </el-col>
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon4.png" alt="" />
                        <div style="display: inline-block;cursor: pointer;" @click="toPurchaseRecord">
                            <div class="value" style="color:var(--themeColor);">{{dataObjOne.purchaseAmount}}</div>
                            <div class="label">采购金额</div>
                        </div>
                    </el-col>
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon5.png" alt="" />
                        <div style="display: inline-block;">
                            <div class="value">{{dataObjOne.accountsReceivable}}</div>
                            <div class="label">应收货款</div>
                        </div>
                    </el-col>
                    <el-col :span="6" class="oneItem">
                        <img class="icon" src="@/assets/workbench/icon6.png" alt="" />
                        <div style="display: inline-block;">
                            <div class="value">{{dataObjOne.sameMonthCollection}}</div>
                            <div class="label">本月收款</div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <!-- <el-card style="margin-top:20px;">
                <div slot="header">
                    <span class="title">营销简报</span>
                </div>
                <div class="emailBox">
                    <div class="item">
                        <div class="value">{{dataObjTwo.recipientsNum}}个</div>
                        <div class="label">收件人</div>
                    </div>
                    <div class="item">
                        <div class="value">{{dataObjTwo.massNum}}次</div>
                        <div class="label">群发次数</div>
                    </div>
                    <div class="item">
                        <div class="value">{{dataObjTwo.emailNum}}封</div>
                        <div class="label">邮件封数</div>
                    </div>
                    <div class="item">
                        <div class="value">{{dataObjTwo.openPeopleNum}}个</div>
                        <div class="label">看信人数</div>
                    </div>
                    <div class="item">
                        <div class="value">{{dataObjTwo.openNum}}次</div>
                        <div class="label">看信次数</div>
                    </div>
                    <div class="item">
                        <div class="value">{{dataObjTwo.openRate}}%</div>
                        <div class="label">看信率</div>
                    </div>
                </div>
            </el-card> -->
            <el-card style="margin-top:20px;">
                <div slot="header">
                    <span class="title">跟进详情</span>
                </div>
                <div class="followUpBox">
                    <div class="item" @click="toCustomerFollow(0)">
                        <div class="value" style="color:var(--themeColor);">{{dataObjThree.sevenNoFollow}}</div>
                        <div class="label">7-15天未跟进</div>
                    </div>
                    <div class="item" @click="toCustomerFollow(1)">
                        <div class="value" style="color:var(--themeColor);">{{dataObjThree.fifteenNoFollow}}</div>
                        <div class="label">15-30天未跟进</div>
                    </div>
                    <div class="item" @click="toCustomerFollow(2)">
                        <div class="value" style="color:var(--themeColor);">{{dataObjThree.monthNoFollow}}</div>
                        <div class="label">30-90天未跟进</div>
                    </div>
                    <div class="item" @click="toCustomerFollow(3)">
                        <div class="value" style="color:var(--themeColor);">{{dataObjThree.moreNoFollow}}</div>
                        <div class="label">90天以上未跟进</div>
                    </div>
                    <!-- <div class="item" @click="toCustomerFollow(3)">
                        <div class="value" style="color:var(--themeColor);">{{dataObjThree.yearNoFollow}}</div>
                        <div class="label">一年未跟进</div>
                    </div> -->
                    <!-- <div class="item" @click="toCustomerFollow(4)">
                        <div class="value" style="color:var(--themeColor);">{{dataObjThree.moreNoFollow}}</div>
                        <div class="label">更久未跟进</div>
                    </div> -->
                </div>
            </el-card>
            <el-card style="margin-top:20px;">
                <Calendar />
            </el-card>
        </div>

        <div class="rightBox">
            <el-card class="personCenter">
                <!-- 用户信息 -->
                <div style="display: flex;">
                    <el-avatar :size="70" shape="square" :src="userInfo.companyLogo ? userInfo.companyLogo : circleUrl"></el-avatar>
                    <div style="padding-left: 12px;">
                        <div style="font-size: 16px;font-weight: bold;color: #353A3E;display: flex;align-items: center;height: 32px;">
                            {{userInfo.companyCnFullName}}
                            <div class="setMealName" v-if="userInfo.setMealName">{{userInfo.setMealName}}</div>
                        </div>
                        <div style="font-size: 14px;color:#9C9C9C;margin-top: 6px;">账号有效期：{{ userInfo.endTime }}</div>
                    </div>
                </div>
                <div style="display: flex;margin-top: 12px;padding: 12px;border: 1px solid rgb(238, 238, 238);background: rgb(249, 248, 248);">
                    <el-avatar :size="40" shape="square" :src="userInfo.headPortraitUrl ? userInfo.headPortraitUrl : circleUrl"></el-avatar>
                    <div style="padding-left:12px;">
                        <div>
                            <span style="font-size: 16px;font-weight: bold;color: #353A3E;">{{ userInfo.userCnName }}</span>
                            <span style="font-size: 14px;color: #9C9C9C;margin: 0 6px;">{{userInfo.phone}}</span>
                            <el-tag effect="plain" size="small" type="success" v-show="userInfo.isAdmin">管理员</el-tag>
                        </div>
                        <div style="font-size: 14px;color: #9C9C9C;">{{ userInfo.mailAddress }}</div>
                    </div>
                </div>
                <!-- 工具箱 -->
                <!-- <div class="toolBox">
                    <div style="display: flex;justify-content: space-between;flex-wrap: wrap;">
                        <el-link class="toolItem" href="/#/BillofLadingInquire" target="_blank" :underline="false">
                            <img class="icon" src="@/assets/workbench/tool1.png" alt="" />
                            <span>集装箱订单跟踪</span>
                        </el-link>
                        <el-link class="toolItem" href="/#/CantonFair" target="_blank" :underline="false">
                            <img class="icon" src="@/assets/workbench/tool2.png" alt="" />
                            <span>广交会</span>
                        </el-link>
                    </div>
                </div> -->
            </el-card>
            <!-- 待办提醒 -->
            <el-card class="agendaRemind">
                <div class="header">
                    <span class="tt">待办提醒：</span>
                    <!-- <el-date-picker v-model="value1" type="date" placeholder="今天" size="mini" style="width: 150px"></el-date-picker>
                    <el-select v-model="value2" placeholder="商机" size="mini" style="width: 100px;margin-left: 10px;"></el-select>
                    <el-button type="text" size="mini" icon="el-icon-plus" style="float:right;">添加</el-button> -->
                </div>
                <el-card style="margin-top: 10px;" v-if="scheduleList.length > 0">
                    <div class="remindItem" v-for="(item,index) in scheduleList" :key="index">
                        <div class="leftIcon">
                            <img src="@/assets/workbench/icon11.png" style="width:20px;height:20px;" alt="" />
                        </div>
                        <div class="float: left;">
                            <div class="companyName" :class="item.colortype">
                                <div>【日程】{{item.scheduleName}}</div>
                            </div>
                            <div class="content">
                                <div style="padding-left:10px;">提醒时间：{{item.remindTime}}</div>
                            </div>
                            <div class="content">
                                <div style="padding-left:10px;" v-show="item.endTime">到期时间：{{item.endTime}}</div>
                            </div>
                        </div>
                    </div>
                </el-card>
                <el-card style="margin-top: 10px;" v-if="busList.length > 0">
                    <div class="remindItem" v-for="(item,index) in busList" :key="index">
                        <div class="leftIcon">
                            <img src="@/assets/workbench/icon2.png" alt="" />
                        </div>
                        <div class="float: left;">
                            <div class="companyName" :class="{'red':item.status=='已超时','green':item.status!='已超时'}">
                                <div>【商机】{{item.name}}</div>
                                <div style="font-size: 12px;float:right;">{{item.status}}</div>
                            </div>
                            <div class="content">
                                <div style="padding-left:10px;">到期时间：{{item.endTime}}</div>
                                <!-- <div class="noShow">不显示</div> -->
                            </div>
                        </div>
                    </div>
                </el-card>
                <el-card style="margin-top: 10px;" v-if="mailList.length > 0">
                    <div class="remindItem" v-for="(item,index) in mailList" :key="index">
                        <div class="leftIcon">
                            <img src="@/assets/workbench/icon7.png" alt="" />
                        </div>
                        <div class="float: left;">
                            <div class="companyName">
                                <div>【邮件】{{item.title}}</div>
                            </div>
                            <div class="content">
                                <div style="padding-left:10px;">{{item.content}}</div>
                                <!-- <div class="noShow">不显示</div> -->
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-card>
            <!-- 生日提醒 -->
            <el-card class="birthdayRemind" v-if="birthdayList.length > 0">
                <div class="remindItem" v-for="(item,index) in birthdayList" :key="index">
                    <div class="leftIcon">
                        <img src="@/assets/workbench/icon8.png" alt="" />
                    </div>
                    <div class="float: left;font-size:16px;">
                        <div style="color: #1B2841;font-weight: bold;">{{item.name}}-{{item.value}}</div>
                        <div style="color: #9C9C9C;">将在({{item.date}})过生日</div>
                    </div>
                </div>
            </el-card>
            <!-- 地区时间 -->
            <el-card class="areaTime">
                <div class="timeInfo" v-for="(item,index) in worldTimes.slice(0,5)" :key="index">
                    <div class="label">
                        <img :src="item.flagUrl" alt="" />
                        <span class="sp1">{{item.country}}时间</span>
                        <span class="sp2">({{weekList[item.weekIndex]}}  {{item.am_pm}})</span>
                    </div>
                    <div class="value">
                        <img src="@/assets/workbench/icon9.png" alt="" />
                        <span class="sp3">{{item.stringOne}}  {{item.stringTwo}}</span>
                    </div>
                    <span class="note">{{item.note}}</span>
                    <el-tooltip effect="dark" content="删除时区" placement="top">
                        <i class="el-icon-circle-close delBtn" @click="delTime(item)" v-if="item.country != '中国'"></i>
                    </el-tooltip>
                </div>
                <div class="addBtn" @click="toAddTime" v-show="worldTimes.length<5"><i class="el-icon-plus"></i>添加</div>
            </el-card>
            <!-- 币种汇率 -->
            <el-card class="currencyRate">
                <div class="currencyInfo" v-for="(element,index) in rateList.slice(0,5)" :key="index">
                    <div class="label">
                        <div>
                            <div class="dot"></div>
                            <span style="color:#1B2841;padding-left:10px;">
                                <span>{{element.exchangeRate.currency == 'CNY' ? "人民币" : element.exchangeRate.currencyCn }}</span>
                                <span>({{element.exchangeRate.currency}})</span>
                                <em style="color:#9D9D9D;padding:5px;">兑</em>
                                <span>{{element.exchangeRate.toCurrency == 'CNY' ? "人民币" : element.exchangeRate.currencyCn }}</span>
                                <span>({{element.exchangeRate.toCurrency}})</span>
                            </span>
                        </div>
                    </div>
                    <div class="value">
                        {{element.exchangeRate.amount}}{{element.exchangeRate.currency}}
                        <span>≈</span>
                        {{element.exchangeRate.value}}{{element.exchangeRate.toCurrency}}
                    </div>
                    <el-tooltip effect="dark" content="删除币种" placement="top" v-if="element.exchangeRate.currency !== 'USD'">
                        <i class="el-icon-circle-close delBtn" @click="delCurrency(element)"></i>
                    </el-tooltip>
                </div>
                <div class="addBtn" @click="toAddCurrency" v-show="rateList.length<5"><i class="el-icon-plus"></i>添加</div>
            </el-card>
        </div>

        <!-- 添加时区 -->
        <el-dialog title="添加时区" :visible.sync="addTimeVisible" width="400px">
            <el-select v-model="addTimeObj" size="small" filterable value-key="country">
                <el-option v-for="item in worldOption" :key="item.country" :label="item.country" :value="item" :disabled="item.disable">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addTimeVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addTimeSubmit" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 添加币种汇率 -->
        <el-dialog title="添加币种汇率" :visible.sync="addCurrencyVisible" width="400px">
            <el-select v-model="currencyValue" size="small" filterable>
                <el-option v-for="item in currencyOption" :key="item.currency" :label="item.currency" :value="item.currency">
                    <span>{{item.currency}}({{item.currencyCn}})</span>
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addCurrencyVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addCurrencySubmit" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>
        <TimezoneMapPicker />

    </div>
</template>

<script>
import Calendar from "@/components/Calendar.vue";
import dayjs from "dayjs";
import vueSeamlessScroll from 'vue-seamless-scroll'
import TimezoneMapPicker from "./components/TimezoneMapPicker.vue";
var timer = null;
export default {
    components: { Calendar,vueSeamlessScroll,TimezoneMapPicker },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
        userInfo: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            isYiwu: false,
            //数据简报 邮件详情 跟进详情
            dataObjOne: {},
            dataObjTwo: {},
            dataObjThree: {},
            //商机提醒 邮件提醒 生日提醒 待办日程提醒
            busList: [],
            mailList: [],
            birthdayList: [],
            scheduleList: [],
            //获取汇率
            rateList: [],
            //地区时间
            worldTimes:[],
            weekList: ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ],
            //添加时区
            btnLoading: false,
            addTimeVisible: false,
            worldOption: [],
            addTimeObj: {},
            //添加币种汇率
            addCurrencyVisible: false,
            currencyOption: [],
            currencyValue: "",
            //采购需求
            demandList: [],
            classOption: {
                limitMoveNum: 1,
                direction: 2,
            },
            //默认头像
            circleUrl: require("@/assets/img/headImg.png"),
        };
    },
    methods: {
        toCustomer(){
            this.handleSelect(`/customerManage/myCustomer`);
            this.$router.push(`/customerManage/myCustomer`);
        },
        toBusiness(){
            this.handleSelect(`/business/businessManage`);
            this.$router.push(`/business/businessManage`);
        },
        toOrder(){
            this.handleSelect(`/orderManage/orderList`);
            this.$router.push(`/orderManage/orderList`);
        },
        toPurchaseRecord(){
            this.handleSelect(`/purchase/recordList`);
            this.$router.push(`/purchase/recordList`);
        },
        toCustomerFollow(type){
            this.handleSelect(`/customerManage/myCustomer?lastFollow=${type}`);
            this.$router.push(`/customerManage/myCustomer?lastFollow=${type}`);
        },

        // 数据简报
        getDataBriefing(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetDataBriefing`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.dataObjOne = data;
                }
            });
        },
        // 邮件详情
        mailDetails(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/MailDetails`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.dataObjTwo = data;
                }
            });
        }, 
        // 跟进详情
        followUpDetails(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/FollowUpDetails`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.dataObjThree = data;
                }
            })
        },

        // 商机提醒
        getBusinessChance(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetBusinessChance`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.forEach(element => {
                        if( new Date().getTime() >= new Date(element.endTime).getTime() ){
                            element.status = "已超时";
                        }else{
                            element.status = "未超时";
                        }
                    });
                    this.busList = data;
                }
            })
        },
        // 邮件提醒
        getEmails(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetEmails`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.mailList = data;
                }
            })
        },
        // 生日提醒
        getBirthday(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetBirthday`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.birthdayList = data;
                }
            })
        },
        // 待办日程提醒
        getAgencySchedules(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetAgencySchedules`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.forEach(element => {
                        element.colortype = "colortype" + element.type;
                    });
                    this.scheduleList = data;
                }
            })
        },

        // 获取汇率
        getExchangeRate(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetNewExchangeRate`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.rateList = data;
                }
            })
        },
        // 添加币种汇率
        toAddCurrency(){
            this.addCurrencyVisible = true;
            this.currencyValue = "";
            this.$axios.get(`${this.$customerUrl}/v1/Config/V2/GetExchangeRate`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        addCurrencySubmit(){
            if(!this.currencyValue){
                this.$message({ message: "请选择币种!", type: "warning"});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/v1/Config/CurrencyConfigSave`,{
                key: "",
                currency: this.currencyValue
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.addCurrencyVisible = false;
                if (isSuccess) {
                    this.getExchangeRate();
                }
            })
        },
        // 删除币种汇率
        delCurrency(item){
            this.$confirm('此操作将删除币种汇率, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/v1/Config/CurrencyConfigReason?key=${item.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.getExchangeRate();
                        this.$message({ type: 'success', message: '操作成功!'});
                    }
                })
            }).catch(() => {});
        },

        // 地区时间
        getTimeZone(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetTimeZone`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    let nowTime = new Date(data.time).getTime();
                    clearInterval(timer)
                    timer = setInterval(() => {
                        nowTime += 1000;
                        data.worldTimes.forEach(item =>{
                            if(item.timeZone != 0){
                                item.timeString = dayjs(nowTime).add(item.timeZone,'hour').format('YYYY-MM-DD HH:mm:ss');
                            }else{
                                item.timeString = dayjs(nowTime).format('YYYY-MM-DD HH:mm:ss');
                            }
                            //星期几
                            item.weekIndex = new Date(item.timeString).getDay();
                            //几点钟
                            let hour = new Date(item.timeString).getHours();
                            if(hour >= 1 && hour < 6){
                                item.am_pm = "凌晨";
                            }else if(hour >= 6 && hour < 9){
                                item.am_pm = "早上";
                            }else if(hour >= 9 && hour < 12){
                                item.am_pm = "上午";
                            }else if(hour >= 12 && hour < 14){
                                item.am_pm = "中午";
                            }else if(hour >= 14 && hour < 18){
                                item.am_pm = "下午";
                            }else if(hour >= 18 && hour < 22){
                                item.am_pm = "晚上";
                            }else{
                                item.am_pm = "深夜";
                            }
                            if(hour < 12){
                                item.note = "AM";
                            }else{
                                item.note = "PM";
                            }
                            if(item.weekIndex == 0 || item.weekIndex == 6){
                                item.note = "非工作时间";
                            }
                            //国旗
                            item.flagUrl = window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.country) : '';
                            //时间字符串拆解
                            item.stringOne = dayjs(item.timeString).format('MM-DD');
                            item.stringTwo = dayjs(item.timeString).format('HH:mm:ss');
                        })
                        this.worldTimes = data.worldTimes;
                    }, 1000);
                }
            })
        },
        // 添加时区
        toAddTime(){
            this.addTimeVisible = true;    
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetWorldTimesBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.worldOption = data;
                }
            })
        },
        addTimeSubmit(){
            if(!this.addTimeObj.country){
                this.$message({ message: "请选择国家!", type: "warning"});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/WorkBench/SaveTimeZone`,this.addTimeObj).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.addTimeVisible = false;
                if (isSuccess) {
                    this.addTimeObj = {};
                    this.getTimeZone();
                }
            })
        },
        // 删除时区
        delTime(item){
            this.$confirm('此操作将删除时区, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/WorkBench/DeleteTimeZone?key=${item.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.getTimeZone();
                        this.$message({ type: 'success', message: '操作成功!'});
                    }
                })
            }).catch(() => {});
        },

        //采购需求
        // getDemand(){
        //     this.$axios.post(`${this.$OAService}/GetPurchaseDemandList`,{
        //         title: "",
        //         describe: "",
        //         pageIndex: 1,
        //         pageSize: 10,
        //     }).then((res) => {
        //         const { isSuccess, data } = res;
        //         if (isSuccess) {
        //             this.demandList = data.datas;
        //         }
        //     })
        // },
        // toViewDemand(){
        //     this.handleSelect(`/purchaseDemand`);
        //     this.$router.push(`/purchaseDemand`);
        // },

        
        

    },
    mounted() {
        // 义乌塞尔(特殊处理)
        let companyKey = this.userInfo.companyKey;
        this.isYiwu = companyKey == "8967ee9a38054e4d9e68ad800533d223" ? true : false;

        this.getDataBriefing();
        this.mailDetails();
        this.followUpDetails();

        this.getBusinessChance();
        this.getEmails();
        this.getBirthday();
        this.getAgencySchedules();

        this.getExchangeRate();
        this.getTimeZone();

        // this.getDemand();
        
    },
    beforeMount() {
        const token = this.$route.query.t;
        if (token) {
            localStorage.setItem("token", token);
            this.$cookieStore.setCookie("token", token, 24*7);
        }
    },
    watch:{
        "userInfo.companyKey"(){
            let companyKey = this.userInfo.companyKey;
            this.isYiwu = companyKey == "8967ee9a38054e4d9e68ad800533d223" ? true : false;
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body{
    padding: 10px 20px;
}
.workbench{
    overflow: hidden;
    .leftBox{
        width: calc(100% - 440px);
        float: left;
        margin-right: 20px;
        height: calc(100vh - 80px);
        overflow: auto;
        .title{
            font-size: 18px;
            font-weight: bold;
            color: #1B2841;
        }
        .timeSlot{
            padding-left: 10px;
            font-size: 14px;
            color: #C7C7C7;
        }
        .value{
            font-size: 22px;
            font-weight: bold;
            color: #1B2841;
        }
        .label{
            font-size: 16px;
            color: #636363;
        }
        .dataPresentation{
            .oneItem{
                text-align: center;
                .icon{
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        .emailBox{
            display: flex;
            .item{
                width: 16.6%;
                text-align: center;
            }
        }
        .followUpBox{
            display: flex;
            .item{
                width: 25%;
                text-align: center;
                cursor: pointer;
            }
        }
        .is-selected {
            color: var(--themeColor);
        }
    }
    .rightBox{
        width: 420px;
        float: right;
        height: calc(100vh - 80px);
        overflow: auto;
        .personCenter{
            .setMealName {
                margin-left: 5px;
                padding: 0 10px;
                width: 40px;
                text-align: center;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                color: #A26B0F;
                background: linear-gradient(90deg, #FEE1B2 0%, #F0B553 100%);
                border-radius: 10px;
            }
        }
        .toolBox{
            margin-top: 12px;
            .toolItem{
                width: calc(50% - 15px);
                height: 40px;
                line-height: 40px;
                background: #FFFFFF;
                box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.16);
                .icon{
                    vertical-align: middle;
                    margin-right: 5px;
                }
            }
        }
        /** 待办提醒 **/
        .agendaRemind{
            margin-top: 12px;
            .header{
                line-height: 28px;
                overflow: hidden;
                .tt{
                    font-size: 16px;
                    font-weight: bold;
                    color: #1D1D26;
                }
            }
            .remindItem{
                overflow: hidden;
                border-bottom: 1px solid #E6E7EB;
                padding-bottom: 5px;
                .leftIcon{
                    margin-right: 10px;
                    float: left;
                    height: 60px;
                    line-height: 60px;
                }
                .companyName{
                    display: flex;
                    justify-content: space-between;
                    line-height: 32px;     
                    color: #6A6A6A;
                }
                .red{
                    color: #FF261E;
                }
                .green{
                    color: #2CB47F;
                }
                .content{
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    line-height: 24px;
                    color: #9D9D9D;
                    .noShow{
                        width: 50px;
                        text-align: center;
                        height: 24px;
                        background: rgba(112, 112, 112, 0.2);                    
                        color: #707070;
                        border-radius: 2px 2px 2px 2px;
                        border: 1px solid #C7C7C7;
                        cursor: pointer;
                    }
                }
            }
            .remindItem:last-child{
                border: none;
            }
        }
        /** 生日提醒 **/
        .birthdayRemind{
            margin-top: 12px;
            .remindItem{
                overflow: hidden;
                border-bottom: 1px solid #E6E7EB;
                padding: 5px 0;
                .leftIcon{
                    margin: 0 20px;
                    float: left;
                    height: 50px;
                    line-height: 50px;
                }
            }
            .remindItem:last-child{
                border: none;
            }
        }
        /** 地区时间 **/
        .areaTime{
            margin-top: 12px;
            .timeInfo{
                border-bottom: 1px solid #E6E7EB;
                padding: 10px 0 10px 20px;
                position: relative;
                .label{
                    display: flex;
                    height: 20px;
                    line-height: 20px;
                    .sp1{
                        font-weight: bold;
                        color: #1B2841;
                        padding-left: 10px;
                    }
                    .sp2{
                        font-weight: 500;
                        color: #9D9D9D;
                        padding-left: 20px;
                    }
                }
                .value{
                    margin-top: 10px;
                    display: flex;
                    height: 20px;
                    line-height: 20px;
                    img{
                        padding-left: 10px;
                    }
                    .sp3{
                        font-weight: bold;
                        color: #1B2841;
                        padding-left: 10px;
                    }
                }
                .delBtn{
                    position: absolute;
                    cursor: pointer;
                    right: 0;
                    bottom: 16px;
                    display: none;
                }
                &:hover .delBtn{
                    display: inline-block;
                }
                .note{
                    position: absolute;
                    right: 0;
                    top: 8px;
                    font-weight: 500;
                    color: #9D9D9D;
                }
            }
            .addBtn{
                height: 28px;
                line-height: 28px;
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                color: #707070;
            }
        }
        /** 币种汇率 **/
        .currencyRate{
            margin-top: 12px;
            .currencyInfo{
                border-bottom: 1px solid #E6E7EB;
                padding: 5px 0 5px 20px;
                position: relative;
                .label{
                    display: flex;
                    justify-content: space-between;
                    height: 20px;
                    line-height: 20px;
                    .dot{
                        width: 14px;
                        height: 14px;
                        display: inline-block;
                        background: #E56210;
                        border-radius: 50%;
                    }
                    img{
                        width: 21px;
                        height: 14px;
                    }
                }
                .value{
                    margin-top: 5px;
                    height: 40px;
                    line-height: 40px;           
                    font-size: 20px;
                    font-weight: bold;
                    color: #1B2841;
                }
                .delBtn{
                    position: absolute;
                    cursor: pointer;
                    right: 0;
                    bottom: 16px;
                    display: none;
                }
                &:hover .delBtn{
                    display: inline-block;
                }
            }
            .addBtn{
                height: 28px;
                line-height: 28px;
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                color: #707070;
            }
        }
    }
    /deep/.el-card__header {
        padding: 12px 20px;
    }
    /deep/.el-card__body {
        padding: 15px 20px;
    }
}
.colortype0{
    color: #FEC000 !important;
}
.colortype1{
    color: #4472C4 !important;
}
.colortype2{
    color: #41B052 !important;
}
.colortype3{
    color: #FF504F !important;
}

.warpBox {
    height: 32px;
    line-height: 32px;
    margin: 0 auto 20px;
    overflow: hidden;
    background-color: #fafafa;
    cursor: pointer;
    .ul-item {
        display: flex;
        .li-item {
            width: 1500px;
            height: 32px;
            line-height: 32px;
            margin-right: 100px;
            color: var(--themeColor);
            font-size: 16px;
        }
    }
}

.upgradeNotice{
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    .gg1{
        width: 42vh;
        height: 66vh;
        position: absolute;
        top: 10vh;
        left: 0;
        right: 0;
        margin: auto;
    }
    .gg2{
        position: absolute;
        bottom: 15vh;
        left: 0;
        right: 0;
        margin: auto;
        cursor: pointer;
    }
}
</style>
