<template>
    <div v-loading="infoLoading">
        <div class="basicInfo">
            <div class="title">我方信息</div>
            <el-form :model="basicInfo" label-width="120px" size="small">
                <el-row :gutter="16">
                    <el-col :span="6">
                        <el-form-item label="公司名称:">
                            <span class="valueColor">{{ourCompany}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="业绩归属人:">
                            <span class="valueColor">{{basicInfo.performanceAttribution}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="业绩归属部门:">
                            <span class="valueColor">{{basicInfo.organizationName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="当前处理人:">
                            <span class="valueColor">{{basicInfo.handler}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div class="basicInfo" style="margin-top:20px;">
            <div class="title">买方信息</div>
            <el-form :model="basicInfo" label-width="120px" size="small">
                <el-row :gutter="16">
                    <el-col :span="6">
                        <el-form-item label="公司名称:">
                            <span class="valueColor">{{basicInfo.customerName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="客户电话:">
                            <span class="valueColor">{{basicInfo.customerTel}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="客户传真:">
                            <span class="valueColor">{{basicInfo.customerFax}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="客户地址:">
                            <span class="valueColor">{{basicInfo.customerAddress}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系人名称:">
                            <span class="valueColor">{{basicInfo.contactsName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系人电话:">
                            <span class="valueColor">{{basicInfo.contactsCall}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系人邮箱:">
                            <span class="valueColor">{{basicInfo.contactsEmail}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系人地址:">
                            <span class="valueColor">{{basicInfo.customerAddress}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div class="basicInfo" style="margin-top:20px;" v-if="userInfo.isProcureModel">
            <div class="title">供应商</div>
            <SupplierInfo />
        </div>        
        
        <div style="margin-top:20px;">
            <div class="title">订单明细</div>
            <div class="detailBox">
                <el-table :data="orderProducts" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column label="编号">
                        <template slot-scope="scope">
                            {{scope.row.productNo}}
                        </template>
                    </el-table-column>
                    <el-table-column label="产品图片">
                        <template slot-scope="scope">
                            <el-image style="width:50px;height:50px;" :src="scope.row.productImg" :preview-src-list="[scope.row.productImg]"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="产品名称">
                        <template slot-scope="scope">
                            {{scope.row.productName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="型号">
                        <template slot-scope="scope">
                            {{scope.row.specifications}}
                        </template>
                    </el-table-column>
                    <el-table-column label="计量单位">
                        <template slot-scope="scope">
                            {{scope.row.measureUnit}}
                        </template>
                    </el-table-column>
                    <el-table-column label="单价" width="160">
                        <template slot-scope="scope">
                            {{scope.row.unitPrice}}
                        </template>
                    </el-table-column>
                    <el-table-column label="订购数量" width="160">
                        <template slot-scope="scope">
                            {{scope.row.num}}
                        </template>
                    </el-table-column>
                    <el-table-column label="总价" width="160">
                        <template slot-scope="scope">
                            {{scope.row.totalPrice}}
                        </template>
                    </el-table-column>
                    <el-table-column label="币种" width="120">
                        <template slot-scope="scope">
                            {{scope.row.currency}}
                        </template>
                    </el-table-column>
                    <el-table-column label="汇率" width="160">
                        <template slot-scope="scope">
                            {{scope.row.exchangeRate}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="`${basicInfo.currency}总价`" width="160">
                        <template slot-scope="scope">
                            {{(scope.row.totalPrice * scope.row.exchangeRate).toFixed(2) * 1}}
                        </template>
                    </el-table-column>
                    <el-table-column label="备注">
                        <template slot-scope="scope">
                            {{scope.row.remarks}}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="describe">
                    <div class="oneItem">
                        <div>
                            <span>产品总数量: {{productTotalNum}}</span>
                            <!-- <span style="margin-left:50px;">包装体积总计: {{basicInfo.productTotalVolume || "--"}}</span> -->
                            <!-- <span style="margin-left:50px;">包装毛重总计: {{basicInfo.productTotalWeight || "--"}}</span> -->
                        </div>
                        <div>产品总金额: {{ basicInfo.currency }} {{productTotalPrice}}</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div style="height:20px;"></div>
        <div class="title">贸易费用</div>
        <div class="tradeCost">
            <div v-show="tradeExpenses.length == 0" style="text-align: center;color: #7C90A5;padding: 10px;">暂无贸易费用</div>
            <div v-for="(item,index) in tradeExpenses" :key="index" class="itemCost">
                <span style="margin-right: 20px;">{{index+1}}</span>
                <div style="margin-right:50px;">
                    <span>项目：</span>
                    <span class="valueColor">{{item.project}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>金额：</span>
                    <span class="valueColor">{{item.totalPrice}} {{item.currency}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>汇率：</span>
                    <span class="valueColor">{{item.exchangeRate}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>{{ basicInfo.currency }}金额：</span>
                    <span class="valueColor">{{(item.totalPrice * item.exchangeRate).toFixed(2) * 1}}</span>
                </div>
            </div>
        </div>
        <div class="summation">
            <div></div>
            <div>
                <span>贸易费用金额：</span>
                <span>{{ basicInfo.currency }}</span>
                <span style="padding-left:10px;">{{tradeExpensesTotalPrice}}</span>
            </div>
        </div>
        <div class="summation" style="font-size: 16px;font-weight: bold;">
            <div></div>
            <div>
                <span>合计总金额：</span>
                <span>{{ basicInfo.currency }}</span>
                <span style="padding-left:10px;">{{allTotalPrice}}</span>
            </div>
        </div>
        
        <!-- 自定义字段 -->
        <ShowSaveField :documentKey="basicInfo.orderKey" :type="1" />
        <!-- 财务到账 -->
        <FinanceReceipt :basicInfo="basicInfo" />
        <!-- 订单收入 -->
        <OrderGather :basicInfo="basicInfo" />
        <!-- 订单支出 -->
        <OrderSpending :basicInfo="basicInfo" />
        <!-- 货运信息 -->
        <FreightInfo :basicInfo="basicInfo" />

        <!-- 附件管理 -->
        <Fujian :documentKey="basicInfo.orderKey" type="orderFujian" />

        <div style="margin-top:20px;">
            <div class="title">跟进人列表 </div>
            <el-table :data="followUps" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                <el-table-column prop="ownerName" label="跟进人"></el-table-column>
                <el-table-column label="跟进时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.startTime}}</span>
                        <span style="padding:0 5px;">—</span>
                        <span>{{scope.row.endTime}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="proportion" label="佣金比率"></el-table-column>
                <el-table-column prop="remarks" label="备注"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top:20px;">
            <div class="title">系统信息</div>
            <el-row :gutter="16">
                <el-col :span="8" class="systemItem">
                    <div class="label">创建人:</div>
                    <div class="value">{{basicInfo.createUserName}}</div>
                </el-col>
                <el-col :span="8" class="systemItem">
                    <div class="label">创建时间:</div>
                    <div class="value">{{basicInfo.createTime}}</div>
                </el-col>
                <el-col :span="8" class="systemItem">
                    <div class="label">创建方式:</div>
                    <div class="value">{{basicInfo.createType}}</div>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>
import SupplierInfo from "./SupplierInfo.vue";      //供应商
import FinanceReceipt from "./FinanceReceipt.vue";  //财务到账
import OrderGather from "./OrderGather.vue";        //订单收入
import OrderSpending from "./OrderSpending.vue";    //订单支出
import FreightInfo from "./FreightInfo.vue";        //货运信息
import Fujian from "@/components/Fujian.vue";       //附件管理
import ShowSaveField from "@/components/ShowSaveField.vue";
export default {
    components: { SupplierInfo, FinanceReceipt, OrderGather, OrderSpending, FreightInfo, Fujian, ShowSaveField },
    data() {
        return {
            infoLoading: false,
            basicInfo: {},
            followUps: [],
            orderProducts: [],
            ourCompany: "",
            tradeExpenses: [],
            productTotalNum: 0,
            productTotalPrice: 0,
            tradeExpensesTotalPrice: 0,
            allTotalPrice: 0,
        
            userInfo: localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {},
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        //获取订单信息
        getOrderInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$orderUrl}/api/Order/GetOrderDetail?key=${this.orderKey}`).then((res) => {
                const { isSuccess , data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    this.productTotalNum = data.productTotalNum;
                    this.productTotalPrice = data.currencyProductTotalPrice;
                    this.tradeExpensesTotalPrice = data.currencyTradeExpensesTotalPrice;
                    this.allTotalPrice = data.currencyAllTotalPrice;
                    //获取基本信息
                    this.basicInfo = data.order;
                    //获取跟进人列表
                    this.followUps = data.followUps;
                    //获取订单明细        
                    this.orderProducts = data.orderProducts;
                    //获取贸易费用
                    this.tradeExpenses = data.tradeExpenses;
                }
            });
        },

    },
    mounted() {
        this.orderKey = this.$route.query.orderKey;
        this.getOrderInfo();    //获取订单信息

        this.ourCompany = JSON.parse(localStorage.userInfo).companyCnFullName;
    },
};
</script>

<style lang="scss" scoped>
.title{
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 10px;
    border-left: 3px solid var(--themeColor);
    padding-left: 5px;
}
.w200{
    width: 200px;
    height: 32px;
}
.detailBox{
    border: 1px solid #BFBFBF;
    .describe{
        padding: 10px;
        font-size: 14px;
        color: #3A3A3A;
        .oneItem{
            height: 28px;
            line-height: 28px;
            display: flex;
            justify-content: space-between;
        }
        .twoItem{
            margin-top: 10px;
            height: 28px;
            line-height: 28px;
            font-weight: bold;
            text-align: right;
        }
    }
}
.systemItem{
    display: flex;
    font-size: 14px;
    line-height: 28px;
    .label{
        width: 80px;
        color: #9C9C9C;
        text-align: right;
    }
    .value{
        padding-left: 10px;
        color: #3A3A3A;
    }
}
.basicInfo{
    .el-form-item {
        margin-bottom: 0;
    }
    .valueColor{
        color: #3A3A3A;
    }
}
.tradeCost{
    border: 1px solid #EBEEF5;
    color: #5E6D82;
    font-size: 14px;
    .itemCost{
        display: flex;
        height: 32px;
        line-height: 32px;
        padding: 10px;
    }
    .itemCost:nth-child(2n+1){
        background: #F8F7F7;
    }
    .itemCost:nth-child(2n){
        background: #E5E9F0;
    }
    .handleDelBtn{
        margin-top: 6px;
    }
}
.summation{
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    color: #3a3a3a;
    display: flex;
    justify-content: space-between;
}
</style>