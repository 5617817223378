<template>
    <div class="newQuotation">
        <div class="header">
            <span>{{theme}}</span>
            <el-button size="small" @click="goBack" v-if="$route.query.isBack">返回</el-button>
        </div>

        <div class="title">基础信息</div>
        <el-form :model="addForm" label-width="100px" size="small">
            <el-row :gutter="16">
                <el-col :span="6">
                    <el-form-item label="报价单名称" required>
                        <el-input v-model="addForm.name" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="客户" required>
                        <el-select v-model="addForm.customerKey" class="w100" placeholder="请选择客户" filterable @change="chooseCustomer" :disabled="isAbleChoose">
                            <el-option v-for="item of myCustomerOption" :key="item.key" :label="item.name" :value="item.key" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系人">
                        <el-select v-model="addForm.contactKey" class="w100" placeholder="先选择客户,后选择联系人" @change="chooseContact">
                            <el-option v-for="item of contactOption" :key="item.key" :label="item.nickname" :value="item.key" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系邮箱">
                        <el-input v-model="addForm.contactsEmail" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="报价日期" required>
                        <el-date-picker v-model="addForm.quotationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="报价有效期" required>
                        <el-date-picker v-model="addForm.expirationTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <!-- settlementCurrency -->
                    <el-form-item label="结算货币" required>
                        <el-select v-model="addForm.currency" size="small" filterable class="w100" @change="currencyChange">
                            <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="交货地点">
                        <el-input v-model="addForm.placeDelivery" class="w100" name="placeDelivery" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="运输方式">
                        <el-input v-model="addForm.typeShipping" class="w100" name="typeShipping" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="是否含税">
                        <el-radio v-model="addForm.isTaxIncluded" :label="true">是</el-radio>
                        <el-radio v-model="addForm.isTaxIncluded" :label="false">否</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="支付方式">
                        <el-input v-model="addForm.paymentMethod" class="w100" name="paymentMethod" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注">
                        <el-input v-model="addForm.remarks" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="title">
            <span style="color: #ff2d2d">*</span>
            <span>报价产品</span>
            <span style="color: #afafaf">(必选项)</span>
        </div>
        <el-table :data="quotedProducts" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="产品名称" width="200">
                <template slot-scope="scope">
                    <span style="color: #ff2d2d">*</span>
                    <el-select v-model="scope.row.productKey" filterable placeholder="请选择产品" size="small" style="width:160px;"
                        @change="(value) => selectProduct(value,scope.row)">
                        <el-option v-for="item in productOption" :key="item.key" :label="item.productName" :value="item.key">
                            {{item.productName}}(编号:{{item.productNo}})(规格:{{item.dimension}})
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="单价" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.unitPrice" size="small" :min="0" :controls="false" @blur="calcTotalPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="订购数量" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.num" size="small" :min="0" :controls="false" @blur="calcTotalPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="总价" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.totalPrice" size="small" :min="0" :controls="false" @blur="calcCurrencyPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="币种" width="120">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.currency" size="small" filterable class="w100" @change="(value)=>chooseCurrency(value,scope.row)">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="汇率" width="160">
                <template slot-scope="scope">
                    <el-input-number :disabled="scope.row.currency==addForm.currency" v-model="scope.row.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCurrencyPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <!-- <el-table-column label="人民币总价" width="160">
                <template slot-scope="scope">
                    <el-input-number :disabled="scope.row.currency=='CNY'" v-model="scope.row.cnyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(scope.row)"></el-input-number>
                </template>
            </el-table-column> -->
            <el-table-column :label="`${addForm.currency}总价`" width="160">
                <template slot-scope="scope">
                    <el-input-number :disabled="scope.row.currency==addForm.currency" v-model="scope.row.currencyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.remarks" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                    <div class="handleDelBtn" @click="delProductItem(scope.$index)">删除</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottomAddBtn" @click="addProductItem">
            <img src="@/assets/icon/plus.png" alt="" />新增产品
        </div>
        <div class="summation">
            <div>
                <span>产品总数量：</span>
                <span style="padding-left:10px;">{{productTotalNum}}</span>
            </div>
            <div>
                <span>产品总金额：</span>
                <span>{{ addForm.currency }}</span>
                <span style="padding-left:10px;">{{productTotalPrice}}</span>
            </div>
        </div>

        <div class="title" style="margin-top:20px;">贸易费用</div>
        <div class="tradeCost">
            <div v-show="tradeExpenses.length == 0" style="text-align: center;color: #7C90A5;padding: 10px;">暂无贸易费用</div>
            <div v-for="(item,index) in tradeExpenses" :key="index" class="itemCost">
                <span style="margin-right: 20px;">{{index+1}}</span>
                <div style="margin-right: 20px;">
                    <span>项目：</span>
                    <el-select v-model="item.project" size="small" style="width:180px;" filterable allow-create>
                        <el-option v-for="item in projectOption" :key="item.key" :label="item.name" :value="item.name"></el-option>
                        <div class="toConfig" @click="toConfig">
                            <i class="el-icon-plus"></i>
                            <span>配置费用</span>
                        </div>
                    </el-select>
                </div>
                <div style="margin-right: 20px;">
                    <span>金额：</span>
                    <el-input-number v-model="item.totalPrice" size="small" :min="0" :controls="false" @blur="calcCurrencyPrice(item)"></el-input-number>
                </div>
                <div style="margin-right: 20px;">
                    <span>币种：</span>
                    <el-select v-model="item.currency" size="small" filterable style="width:100px;" @change="(value)=>chooseCurrency(value,item)">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </div>
                <div style="margin-right: 20px;">
                    <span>汇率：</span>
                    <el-input-number :disabled="item.currency==addForm.currency" v-model="item.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCurrencyPrice(item)"></el-input-number>
                </div>
                 <!-- <div style="margin-right: 20px;">
                    <span>人民币金额：</span>
                    <el-input-number :disabled="item.currency=='CNY'" v-model="item.cnyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(item)"></el-input-number>
                </div> -->
                <div style="margin-right: 20px;">
                    <span>{{addForm.currency}}金额：</span>
                    <el-input-number :disabled="item.currency==addForm.currency" v-model="item.currencyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(item)"></el-input-number>
                </div>
                <div class="handleDelBtn" @click="delCostItem(index)">删除</div>
            </div>
        </div>
        <div class="bottomAddBtn" @click="addCostItem">
            <img src="@/assets/icon/plus.png" alt="" />新增费用
        </div>
        <div class="summation">
            <div></div>
            <div>
                <span>贸易费用金额：</span>
                <span>{{ addForm.currency }}</span>
                <span style="padding-left:10px;">{{tradeExpensesTotalPrice}}</span>
            </div>
        </div>
        <div class="summation" style="font-size: 16px;font-weight: bold;">
            <div></div>
            <div>
                <span>合计总金额：</span>
                <span>{{ addForm.currency }}</span>
                <span style="padding-left:10px;">{{allTotalPrice}}</span>
            </div>
        </div>

        <div class="title" style="margin-top:20px;">
            <span style="color: #ff2d2d">*</span>
            <span>报价人信息</span>
            <span style="color: #afafaf">(选择报价人 or 填写报价人)</span>
        </div>
        <div class="quotedContact">
            <el-row :gutter="24">
                <el-col :span="24" class="fieldItem">
                    <span>选择报价人：</span>
                    <el-select v-model="addForm.quotedContactKey" placeholder="选择报价人信息" size="small" @change="chooseQuotedContact">
                        <el-option v-for="item of quotedContactOption" :key="item.quotedContactKey" :label="item.name + ' -- ' + item.companyName" :value="item.quotedContactKey"></el-option>
                    </el-select>
                    <el-button @click="chooseQuotedContact('')" size="small" v-show="addForm.quotedContactKey">填写报价人</el-button>
                </el-col>
                <el-col :span="8" class="fieldItem">
                    <span style="color: #ff2d2d">*</span>
                    <span>姓名：</span>
                    <el-input v-model="quotedContact.name" size="small" style="width:200px;" :disabled="addForm.quotedContactKey!=''"></el-input>
                </el-col>
                <el-col :span="8" class="fieldItem">
                    <span>公司：</span>
                    <el-input v-model="quotedContact.companyName" size="small" style="width:200px;" :disabled="addForm.quotedContactKey!=''"></el-input>
                </el-col>
                <el-col :span="8" class="fieldItem">
                    <span>地址：</span>
                    <el-input v-model="quotedContact.address" size="small" style="width:200px;" :disabled="addForm.quotedContactKey!=''"></el-input>
                </el-col>
                <el-col :span="8" class="fieldItem">
                    <span style="padding:0 3px;"></span>
                    <span>电话：</span>
                    <el-input v-model="quotedContact.tel" size="small" style="width:200px;" :disabled="addForm.quotedContactKey!=''"></el-input>
                </el-col>
                <el-col :span="8" class="fieldItem">
                    <span>邮箱：</span>
                    <el-input v-model="quotedContact.email" size="small" style="width:200px;" :disabled="addForm.quotedContactKey!=''"></el-input>
                </el-col>
                <el-col :span="8" class="fieldItem">
                    <span>备注：</span>
                    <el-input v-model="quotedContact.remarks" size="small" style="width:200px;" :disabled="addForm.quotedContactKey!=''"></el-input>
                </el-col>
            </el-row>
        </div>
        
        <div class="submitBtn">
            <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="isSubmit" size="small">
                立即创建
            </el-button>
        </div>
        
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            theme: "新建报价单",
            btnLoading: false,
            isSubmit: false,
            isAbleChoose: false,

            myCustomerOption: [],
            currencyOption: [],
            contactOption: [],
            productOption: [],
            quotedContactOption: [],
            projectOption: [
                {key: 1, name: "关税"},
                {key: 2, name: "运费"},
                {key: 3, name: "保险"},
                {key: 4, name: "国内运费"},
            ],
        
            addForm: {
                "key": "",
                "name": "",
                "customerKey": "",
                "customerName": "",
                "customerCall": "",
                "customerFax": "",
                "customerAddress": "",
                "quotationDate": dayjs().format('YYYY-MM-DD'),
                "settlementCurrency": "CNY",
                "currency": "CNY",
                "exchangeRate": "1",
                "placeDelivery": "",
                "typeShipping": "",
                "expirationTime": dayjs().add(7, 'day').format('YYYY-MM-DD'),
                "isTaxIncluded": false,
                "remarks": "",
                "contactKey": "",
                "contactsName": "",
                "contactsCall": "",
                "contactsEmail": "",
                "contactsAddress": "",
                "shipmentPeriod": "",           //装运期限
                "loadingPort": "",              //装运港口
                "destinationPort": "",          //目的港口
                "shippingPeriodDescription": "",//装运期限说明
                "paymentMethod": "",            //支付方式
                "quotedContactKey": "",
            },            
            quotedProducts: [],
            tradeExpenses: [],
            quotedContact: {
                "name": "",
                "companyName": "",
                "address": "",
                "tel": "",
                "email": "",
                "remarks": ""
            }
        };
    },
    computed:{
        //产品总数量
        productTotalNum(){
            let productTotalNum = 0;
            this.quotedProducts.forEach(ele =>{
                if(ele.productKey){
                    productTotalNum += ele.num;
                }
            })
            return productTotalNum;
        },
        //产品总金额
        productTotalPrice(){
            let productTotalPrice = 0;
            this.quotedProducts.forEach(ele =>{
                if(ele.productKey){
                    productTotalPrice += ele.currencyTotalPrice;
                }
            })
            return productTotalPrice.toFixed(2) * 1;
        },
        //贸易费用金额
        tradeExpensesTotalPrice(){
            let tradeExpensesTotalPrice = 0;
            this.tradeExpenses.forEach(ele =>{
                tradeExpensesTotalPrice += ele.currencyTotalPrice;
            })
            return tradeExpensesTotalPrice.toFixed(2) * 1;
        },
        //合计总金额
        allTotalPrice(){
            let allTotalPrice = this.productTotalPrice + this.tradeExpensesTotalPrice;
            return allTotalPrice.toFixed(2) * 1;
        },
    },
    methods: {
        goBack(){
            this.$router.push(`/customerManage/customerDetailsPrivate?customerKey=${this.$route.query.customerKey}&activeName=quotation`);
        },

        //获取产品列表
        getProductList(){
            this.$axios.post(`${this.$productUrl}/api/Product/List`,{
                pageIndex: 1,
                pageSize: 500
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.productOption = data.datas;
                }
            });
        },        
        addProductItem(){
            this.quotedProducts.push({
                "productKey": "",
                "productNo": "",
                "productName": "",
                "specifications": "",
                "productDescription": "",
                "unitPrice": 0,
                "totalPrice": 0,
                "num": 1,
                "measureUnit": "",
                "otherExpenses": 0,
                "currency": this.addForm.currency,
                "exchangeRate": "1",
                "cnyTotalPrice": 0,
                "currencyTotalPrice": 0,
                "remarks": ""
            })
        },
        delProductItem(index){
            this.quotedProducts.splice(index,1);
        },
        
        addCostItem(){
            this.tradeExpenses.push({
                "project": "",
                "totalPrice": 0,
                "currency": this.addForm.currency,
                "exchangeRate": "1",
                "cnyTotalPrice": 0,
                "currencyTotalPrice": 0,
                "remarks": "",
            })
        },
        delCostItem(index){
            this.tradeExpenses.splice(index,1);
        },

        //选择币种获取汇率
        currencyChange(value){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            this.addForm.exchangeRate = cItem?cItem.exchangeRate:"1";
            //重新计算表格里面的汇率
            this.quotedProducts.forEach((row)=>{
                this.chooseCurrency(row.currency,row);
            })
            this.tradeExpenses.forEach((row)=>{
                this.chooseCurrency(row.currency,row);
            })
        },
        //选择产品
        selectProduct(value,row){
            let pItem = this.productOption.filter(ele => ele.key == value)[0];
            row.unitPrice = pItem.taxIncludingPrice;
            this.calcTotalPrice(row);
        },
        //通过单价和数量,计算总价
        calcTotalPrice(row){
            row.totalPrice = row.num * row.unitPrice;
            this.calcCurrencyPrice(row);
        },
        //选择货币类型
        chooseCurrency(value,row){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            row.exchangeRate = cItem.exchangeRate / this.addForm.exchangeRate;
            this.calcCurrencyPrice(row);
        },
        //通过总价和汇率,计算人名币总价
        //row.cnyTotalPrice = row.totalPrice * row.exchangeRate * 1;\
        //row.cnyTotalPrice = row.cnyTotalPrice.toFixed(2) * 1;
        calcCurrencyPrice(row){
            row.currencyTotalPrice = row.totalPrice * row.exchangeRate * 1;
            row.currencyTotalPrice = row.currencyTotalPrice.toFixed(2) * 1;
        },
        //通过总价和人民币总价,计算汇率
        calcRate(row){
            row.exchangeRate = row.currencyTotalPrice / row.totalPrice;
            row.exchangeRate = row.exchangeRate.toFixed(8) * 1;
        },
        

        //提交数据
        onSubmit(){
            if(!this.addForm.name){
                this.$message({ message: '请填写报价单名称', type: 'warning'});
                return false;
            }
            if(!this.addForm.customerKey){
                this.$message({ message: '请选择客户', type: 'warning'});
                return false;
            }
            if(this.productTotalNum == 0){
                this.$message({ message: '请补充报价产品', type: 'warning'});
                return false;
            }
            if(this.productTotalPrice == 0){
                this.$message({ message: '产品总金额不能为0', type: 'warning'}); 
                return false;
            }
            this.quotedProducts = this.quotedProducts.filter(ele => ele.productKey !== "");
            this.tradeExpenses = this.tradeExpenses.filter(ele => ele.project !== "");
            if(!this.addForm.quotedContactKey && !this.quotedContact.name){
                this.$message({ message: '请补充报价人信息', type: 'warning'});
                return false;
            }
            // console.log(this.quotedProducts);
            // console.log(this.tradeExpenses);
            // console.log(this.quotedContact);
            this.addForm.settlementCurrency = this.addForm.currency;
            // 请求接口
            this.btnLoading = true;
            this.$axios.post(`${this.$sjUrl}/Quote/SaveQuote`,{
                quote: this.addForm,
                quotedProducts: this.quotedProducts,
                tradeExpenses: this.tradeExpenses,
                quotedContact: this.quotedContact,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    let businessKey = this.$route.query.businessKey;
                    if(businessKey){
                        this.relationSj(data,businessKey); //关联商机
                    }
                    this.$message({ message: '提交成功', type: 'success'});
                    this.isSubmit = true;
                    this.handleSelect(`/quotation/quotationDetails?quotationKey=${data}`);
                    this.$router.push(`/quotation/quotationDetails?quotationKey=${data}`);
                }
            });
        },

        //关联商机
        relationSj(dataKey,businessKey){
            this.$axios.post(`${this.$sjUrl}/BusinessChance/AddBusinessChanceRelation`,{
                dataKey,businessKey,type: 1,
            }).then((res) => {});
        },


        //获取我的客户列表
        getMyCustomer(){
            this.$axios.get(`${this.$customerUrl}/Company/GetCustomerBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.myCustomerOption = data;
                }
            });
        },
        //获取币种配置
        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        chooseCustomer(value){
            this.$axios.get(`${this.$customerUrl}/Company/GetContacters?customerKey=${value}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.contactOption = data;
                    this.addForm.customerName = "";
                    this.addForm.customerCall = "";
                    this.addForm.customerFax = "";
                    this.addForm.customerAddress = "";
                    this.addForm.contactKey = "";
                    this.addForm.contactsName = "";
                    this.addForm.contactsCall = "";
                    this.addForm.contactsEmail = "";
                }
            });
        },
        chooseContact(value){
            let chooseItem =  this.contactOption.filter(ele => ele.key == value)[0];
            this.addForm.contactKey = value;
            this.addForm.contactsName = chooseItem.nickname;
            this.addForm.contactsCall = chooseItem.call;
            this.addForm.contactsEmail = chooseItem.email;
        },
        //获取报价联系人列表
        getQuotedContact(){
            this.$axios.get(`${this.$sjUrl}/Quote/GetContacts`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.quotedContactOption = data;
                }
            });
        },
        chooseQuotedContact(value){
            if(value){
                this.addForm.quotedContactKey = value;
                let chooseItem =  this.quotedContactOption.filter(ele => ele.quotedContactKey == value)[0];
                this.quotedContact = chooseItem;
            }else{
                this.addForm.quotedContactKey = "";
                this.quotedContact = {
                    "name": "",
                    "companyName": "",
                    "address": "",
                    "tel": "",
                    "email": "",
                    "remarks": ""
                }
            }
        },

        //获取报价单详情
        getQuoteDetail(quotationKey,optype){
            this.$axios.get(`${this.$sjUrl}/Quote/GetQuoteDetail?key=${quotationKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    data.quote.currency = data.quote.settlementCurrency;
                    this.addForm = data.quote;
                    if(data.quote.exchangeRate=="0.0000"){
                        this.currencyChange(data.quote.currency);
                    }
                    data.quotedProducts.forEach(row=>{
                        row.currencyTotalPrice = (row.totalPrice * row.exchangeRate).toFixed(2) * 1;
                    })
                    this.quotedProducts = data.quotedProducts;
                    data.tradeExpenses.map(row=>{
                        row.currencyTotalPrice = (row.totalPrice * row.exchangeRate).toFixed(2) * 1;
                    })
                    this.tradeExpenses = data.tradeExpenses;
                    this.quotedContact = data.quotedContact;
                    if(optype == 'copy'){
                        this.addForm.key = "";
                        this.theme = "复制报价单";
                        document.title = "复制报价单";
                    }else if(optype == 'edit'){
                        this.theme = "编辑报价单";
                        document.title = "编辑报价单";
                    }
                    if(this.addForm.customerKey){
                        this.$axios.get(`${this.$customerUrl}/Company/GetContacters?customerKey=${this.addForm.customerKey}`).then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                this.contactOption = data;
                            }
                        });
                    }
                }
            });
        },

        //获取费用配置
        getProject(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetTradeExpensesProject`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.projectOption = data;
                }
            });
        },
        toConfig(){
            let routeData = this.$router.resolve({
                path: "/settingIndex",
                query: { type: "system" },
            });
            window.open(routeData.href, "_blank");
        },
        
    },
    mounted() {
        this.getMyCustomer();       //获取我的客户列表
        this.getCurrencyConfig();   //获取币种配置
        this.getProductList();      //获取产品列表
        this.getQuotedContact();    //获取报价联系人列表
        this.getProject();          //获取费用配置

        let quotationKey = this.$route.query.quotationKey;
        let optype = this.$route.query.optype;
        if(quotationKey && optype){
            this.getQuoteDetail(quotationKey,optype);
        }else{
            this.addProductItem();  //新增报价单默认添加一项
        }

        //初始化客户信息
        let customerKey = this.$route.query.customerKey;
        if(customerKey){
            this.addForm.customerKey = customerKey;
            this.chooseCustomer(customerKey);
            this.isAbleChoose = true;   //携带了客户信息,不能再选择客户
        }
    },
};
</script>

<style lang="scss" scoped>
.newQuotation{
    background: #FFFFFF;
    padding: 20px;
    .header{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
    .w100{
        width: 100%;
        height: 32px;
    }
}
.bottomAddBtn{
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #7C90A5;
    border: 1px solid #EBEEF5;
    border-top: none;
    img{
        vertical-align: middle;
        padding-right: 10px;
    }
}
.handleDelBtn{
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 40px;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
    background: rgba(255, 86, 86, 0.26);
    border: 1px solid rgba(255, 86, 86, 0.3);
    border-radius: 2px;
    color: #FF5656;
}
.tradeCost{
    border: 1px solid #EBEEF5;
    color: #5E6D82;
    font-size: 14px;
    .itemCost{
        display: flex;
        height: 32px;
        line-height: 32px;
        padding: 10px;
    }
    .itemCost:nth-child(2n+1){
        background: #F8F7F7;
    }
    .itemCost:nth-child(2n){
        background: #E5E9F0;
    }
    .handleDelBtn{
        margin-top: 6px;
    }
}
.quotedContact{
    background: #F6F4F4;
    color: #5E6D82;
    padding: 10px;
    font-size: 14px;
    .fieldItem{
        height: 32px;
        line-height: 32px;
        margin-bottom: 10px;
    }
}
.submitBtn{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}
.summation{
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    color: #3a3a3a;
    display: flex;
    justify-content: space-between;
}
.toConfig{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
</style>