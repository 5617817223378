<template>
  <!--abroad-social互斥-->
  <div id="abroad-social-grfwb" class="abroad-social" v-show="!hidden">
    <div class="title" style="padding-left: 0" ref="title">
      <p>社媒信息推荐</p>
      <feedback position="社媒信息推荐" url="/xxxx" />
    </div>
    <el-tabs type="border-card">
      <el-tab-pane v-if="data.googlemap.data.length">
        <div slot="label" style="width: 120px; height: 52px; font-size: 16px; display: flex; justify-content: center; align-items: center">
          <img src="@/firm/images/company/google.png" style="margin-right: 6px" />
          Google地图
        </div>
        <div>
          <div class="panel-title">
            <img src="@/firm/images/company/google.png" style="margin-right: 6px" />
            <span>Google地图</span>
            <p style="font-size: 13px; margin-left: 10px">
              (相关内容共<span style="color: var(--themeColor, #3152AF)">{{ data.googlemap.total }}条</span>，仅展示TOP5)
            </p>
          </div>
          <div>
            <div class="googlemap-item" v-for="(item, index) of data.googlemap.data" :key="index">
              <div style="font-size: 22px; padding-bottom: 12px; font-weight: bold">{{ item.company }}</div>
              <el-descriptions>
                <el-descriptions-item label="公司名">{{ item.company }}</el-descriptions-item>
                <el-descriptions-item label="网址"><span @click="navigate(item.website)" style="color: var(--themeColor, #3152AF); cursor: pointer">{{ item.website }}</span></el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="电话">{{ item.phone }}</el-descriptions-item>
                <el-descriptions-item label="星级"></el-descriptions-item>
                <el-descriptions-item label="营业时间">{{ item.businessTime }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="地址">{{ item.address }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="地区">{{ item.region }}</el-descriptions-item>
              </el-descriptions>
              <div style="display: flex; align-items: center" v-if="item.avatarUrl">
                <span style="margin-right: 10px; color: #8492a6">产品图片</span>
                <div class="img-container">
                  <el-image :preview-src-list="[item.avatarUrl]" :src="item.avatarUrl" style="margin-right: 20px; height: 100px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="data.facebook.data.length">
        <div slot="label" style="width: 120px; height: 52px; font-size: 16px; display: flex; justify-content: center; align-items: center">
          <img src="@/firm/images/company/facebook.png" style="margin-right: 6px" />
          Facebook
        </div>
        <div>
          <div class="panel-title">
            <img src="@/firm/images/company/facebook.png" style="margin-right: 6px" />
            <span>Facebook</span>
            <p style="font-size: 13px; margin-left: 10px">
              (相关内容共<span style="color: var(--themeColor, #3152AF)">{{ data.facebook.total }}条</span>，仅展示TOP5)
            </p>
          </div>
          <div>
            <div class="facebook-item" v-for="(item, index) of data.facebook.data" :key="index">
              <div style="margin-right: 25px" v-if="item.avatarUrl">
                <el-image :preview-src-list="[item.avatarUrl]" :src="item.avatarUrl" style="width: 80px; height: 80px" />
              </div>
              <div>
                <div style="font-size: 22px; padding-bottom: 12px; font-weight: bold">{{ item.companyName }}</div>
                <el-descriptions>
                  <el-descriptions-item label="链接"><span @click="navigate(item.pageUrl)" style="color: var(--themeColor, #3152AF); cursor: pointer">{{ item.pageUrl }}</span></el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                  <el-descriptions-item label="行业">产品/服务</el-descriptions-item>
                  <el-descriptions-item label="" :labelStyle="{ display: 'none' }"></el-descriptions-item>
                  <el-descriptions-item label="网址"><span @click="navigate(item.website)" style="color: var(--themeColor, #3152AF); cursor: pointer">{{ item.website }}</span></el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                  <el-descriptions-item label="邮箱">{{ item.email }}</el-descriptions-item>
                  <el-descriptions-item label="电话">{{ item.phone }}</el-descriptions-item>
                  <el-descriptions-item label="WhatsApp">{{ item.whatsApp }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                  <el-descriptions-item label="粉丝数">{{ item.fansNumber }}</el-descriptions-item>
                  <el-descriptions-item label="点赞数">{{ item.likes }}</el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="data.yelp.data.length">
        <div slot="label" style="width: 120px; height: 52px; font-size: 16px; display: flex; justify-content: center; align-items: center">
          <img src="@/firm/images/company/yelp.png" style="margin-right: 6px" />
          Yelp
        </div>
        <div>
          <div class="panel-title">
            <img src="@/firm/images/company/yelp.png" style="margin-right: 6px" />
            <span>Yelp</span>
            <p style="font-size: 13px; margin-left: 10px">
              (相关内容共<span style="color: var(--themeColor, #3152AF)">{{ data.yelp.total }}条</span>，仅展示TOP5)
            </p>
          </div>
          <div>
            <div class="yelp-item" v-for="(item, index) of data.yelp.data" :key="index">
              <div style="font-size: 22px; padding-bottom: 12px; font-weight: bold">{{ item.companyName }}</div>
              <div style="width: 80%">
                <el-descriptions>
                  <el-descriptions-item label="链接"><span @click="navigate(item.pageUrl)" style="color: var(--themeColor, #3152AF); cursor: pointer">{{ item.pageUrl }}</span></el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                  <el-descriptions-item label="行业">{{ item.industry }}</el-descriptions-item>
                  <el-descriptions-item label="" :labelStyle="{ display: 'none' }"></el-descriptions-item>
                  <el-descriptions-item label="网址"><span @click="navigate(item.website)" style="color: var(--themeColor, #3152AF); cursor: pointer">{{ item.website }}</span></el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                  <el-descriptions-item label="电话">{{ item.phone }}</el-descriptions-item>
                  <el-descriptions-item label="营业时间">{{ item.businessTime }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions>
                  <el-descriptions-item label="地址">{{ item.address }}</el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="data.yellowpages.data.length">
        <div slot="label" style="width: 120px; height: 52px; font-size: 16px; display: flex; justify-content: center; align-items: center">
          <img src="@/firm/images/company/huangye.png" style="margin-right: 6px" />
          Yellowpages
        </div>
        <div>
          <div class="panel-title">
            <img src="@/firm/images/company/huangye.png" style="margin-right: 6px" />
            <span>Yellowpages</span>
            <p style="font-size: 13px; margin-left: 10px">
              (相关内容共<span style="color: var(--themeColor, #3152AF)">{{ data.yellowpages.total }}条</span>，仅展示TOP5)
            </p>
          </div>
          <div>
            <div class="yellowpages-item" v-for="(item, index) of data.yellowpages.data" :key="index">
              <div style="font-size: 22px; padding-bottom: 12px; font-weight: bold">{{ item.companyName }}</div>
              <el-descriptions>
                <el-descriptions-item label="链接"><span @click="navigate(item.pageUrl)" style="color: var(--themeColor, #3152AF); cursor: pointer">{{ item.pageUrl }}</span></el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="行业">{{ item.industry }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="网址"><span @click="navigate(item.website)" style="color: var(--themeColor, #3152AF); cursor: pointer">{{ item.website }}</span></el-descriptions-item>
                <el-descriptions-item label="电话">{{ item.phone }}</el-descriptions-item>
                <el-descriptions-item label="邮箱">{{ item.email }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="营业时间">{{ item.businessTime }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="地址">{{ item.address }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="描述">{{ item.describe }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import axios from "@/firm/plugins/axios";
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";
import { interceptLink } from "@/firm/utils/link";

export default Vue.extend({
  data() {
    return {
      hidden: false,
      data: {
        googlemap: {
          total: 0,
          data: [],
        },
        facebook: {
          total: 0,
          data: [],
        },
        yelp: {
          total: 0,
          data: [],
        },
        yellowpages: {
          total: 0,
          data: [],
        }
      }
    };
  },
  computed: {
    ...mapState(["company"]),
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    navigate(url) {
      interceptLink(url);
    }
  },
  async mounted() {
    new AnchorInditor(() => {
      this.setCurrent("SOCIAL");
    }, this.$refs["title"]);

    try {
      const res = await axios({
        method: 'GET',
        url: '/search/Credit/EnterpriseLibrary/SocialMediaCompanys',
        params: {
          companyName: this.company,
          countryCode: this.$route.query.countryCode
        }
      })
      const data = res.data.result
      for (const item of data) {
        switch (item.source) {
          case 'googlemap':
            this.data.googlemap.total += 1;
            if (this.data.googlemap.data.length < 5) {
              this.data.googlemap.data.push(item)
            }
            break
          case 'facebook':
            this.data.facebook.total += 1;
            if (this.data.facebook.data.length < 5) {
              this.data.facebook.data.push(item)
            }
            break
        }
      }
      if (this.data.facebook.data.length || this.data.googlemap.data.length || this.data.yellowpages.data.length || this.data.yelp.data.length) {
        this.setAnchorFlag([["SOCIAL", true]])
      } else {
        throw new Error()
      }
    } catch {
      this.hidden = true
    }


  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

@mixin scrollbar-x($backgroundColor: #fff, $thumbColor: #d5d5d5) {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
    background: $backgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    background: #d5d5d5;
  }

  scrollbar-width: thin;
  scrollbar-color: #d5d5d5 $backgroundColor;
}

.abroad-social {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  @include mixin-panel-title;
  color: #1f2d3d;
}

.el-tabs--border-card {
  box-shadow: none !important;

  ::v-deep .el-tabs__header {
    background: #f2f2f2 !important;

    .el-tabs__item {
      color: #545c64;
    }
  }

  ::v-deep .el-tabs__item {
    height: auto;
  }

  ::v-deep .el-tabs__content {
    padding: 0;
  }
}

.panel-title {
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 16px;
  color: #545c64;
  border-bottom: 1px solid #eeeeee;
}

::v-deep .el-descriptions__body {
  color: #1f2d3d;
}

::v-deep .has-colon {
  color: #8492a6;
}

.googlemap-item {
  padding: 10px 25px;
  border-bottom: 1px solid #eeeeee;

  &:last-child {
    border-bottom: none;
  }

  .img-container {
    width: 975px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: nowrap;
    @include scrollbar-x();

    .el-image {
      flex-shrink: 0;
    }
  }
}

.facebook-item {
  padding: 10px 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;

  &:last-child {
    border-bottom: none;
  }
}

.yelp-item {
  padding: 10px 25px;
  border-bottom: 1px solid #eeeeee;

  &:last-child {
    border-bottom: none;
  }

  .img-container {
    width: 975px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: nowrap;
    @include scrollbar-x();

    .el-image {
      flex-shrink: 0;
    }
  }
}

.yellowpages-item {
  padding: 10px 25px;
  border-bottom: 1px solid #eeeeee;

  &:last-child {
    border-bottom: none;
  }

  .img-container {
    width: 975px;
    flex-shrink: 0;
    display: flex;
    flex-wrap: nowrap;
    @include scrollbar-x();

    .el-image {
      flex-shrink: 0;
    }
  }
}
</style>
