<template>
  <!-- 国内企业，企业信息 -->
  <div>
    <div id="enterprise-info-basic-info" class="enterprise-info-basic-info" v-loading="loading" v-show="!hidden">
      <div class="title" ref="title">
        <p>企业信息</p>
        <feedback postion="企业信息" url="/search/Credit/Info" />
        <!-- <div class="flex-container">
          <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
          <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
        </div> -->
      </div>
      <div>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">统一社会信用代码</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.unifysocialcreditcodes }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">企业名称</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.name }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t">法定代表人</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r">
              <div class="pl border l">
                {{ information.legalRepresentative }}<span style="color: var(--themeColor, #3152AF)" v-show="information.associateNum">（关联{{ information.associateNum }}家企业）</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">登记状态</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.registerStatus }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">成立日期</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.establishDate }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">注册资本</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.registerMoney }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">实缴资本</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.paidCapital }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">核准日期</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.approvalDate }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">组织机构代码</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.organizationCode }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">工商注册号</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.registrationNum }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">纳税人识别号</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.identificationNum }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">企业类型</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.enterpriseType }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">营业期限</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.businessTerm }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">纳税人资质</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.taxpayerQualifications }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">人员规模</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.sizeOfPerson }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">参保人数</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.insuredPersons }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">曾用名</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.usedName }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">英文名</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.enName }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">进出口企业代码</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.importAndExportEnterpriseCode }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t">注册地址</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r">
              <div class="pl border l">{{ information.registrationAddress }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">企业类型</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.businessScope }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">纳税人识别号</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.taxpayerIdentificationNum }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="4">
            <div class="pl border l t b" style="height: 100%; display: flex; align-items: center; box-sizing: border-box">经营范围</div>
          </el-col>
          <el-col :span="20">
            <div class="border t r b" style="padding: 0.5em 0">
              <div class="pl border l" style="line-height: 1.5em">{{ information.enterpriseType1 }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <aborad-contact />
    <!-- <aborad-industry /> -->
    <!-- <aborad-sitc /> -->
    <aborad-ussic />
    <aborad-naics />
    <aborad-industry />
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";
import aboradContact from "./abroad/abroad-contact.vue";
import aboradIndustry from "./abroad/abroad-industry.vue";
//import aboradSitc from "./abroad/abroad-sitc.vue";
import aboradUssic from "./abroad/abroad-ussic.vue";
import aboradNaics from "./abroad/abroad-naics.vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../utils";

export default Vue.extend({
  props: ["company", "MPsetInformation"],
  components: {
    aboradContact,
    aboradIndustry,
    //aboradSitc,
    aboradUssic,
    aboradNaics,
  },
  data() {
    return {
      hidden: false,
      loading: false,
      information: {
        unifysocialcreditcodes: "", //统一社会信用代码
        name: "", //企业名称
        legalRepresentative: "", //法定代表人
        associateNum: "", //关联企业数
        registerStatus: "", //登记状态
        establishDate: "", //成立日期
        registerMoney: "", //注册资本
        paidCapital: "", //实缴资本
        approvalDate: "", //核准日期
        organizationCode: "", //组织机构代码
        registrationNum: "", //工商注册号
        identificationNum: "", //纳税人识别号
        enterpriseType: "", //企业类型
        businessTerm: "", //营业期限
        taxpayerQualifications: "", //纳税人资质
        sizeOfPerson: "", //人员规模
        insuredPersons: "", //参保人数
        usedName: "", //曾用名
        enName: "", //英文名
        importAndExportEnterpriseCode: "", //进出口企业代码
        registrationAddress: "", //注册地址
        businessScope: "", //企业类型
        taxpayerIdentificationNum: "", //纳税人识别号
        enterpriseType1: "", //经营范围
      },
      industryCodes: [],
      naics: [],
    };
  },
  methods: {
    ...mapMutations(["setWebsite"]),
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  async mounted() {
    try {
      this.loading = true;

      const result = await axios({
        method: "get",
        url: "/search/Credit/Contact",
        params: {
          keyword: this.company,
        },
      });
      const url1 = result.data.result?.url;
      let urlFlag = true;
      if (url1) {
        this.MPsetInformation(["netaddress", url1]);
        this.setWebsite(url1);
        urlFlag = false;
      }

      const res = await axios({
        method: "get",
        url: "/search/Credit/Info",
        params: {
          keyword: this.company,
        },
      });
      const data = res.data.result;
      this.information = {
        ...this.information,
        unifysocialcreditcodes: data.socialCreditCode || "-", //"-", //统一社会信用代码
        name: data.customerName || "-", //"-", //企业名称
        legalRepresentative: data.legalRepresentative || "-", //"-", //法定代表人
        //associateNum: "-", //关联企业数
        registerStatus: data.operatingStatus || "-", //"-", //登记状态
        establishDate: data.dateEstablishment || "-", //"-", //成立日期
        registerMoney: data.registeredCapital || "-", // ,"-", //注册资本
        paidCapital: data.paidCapital || "-", //,"-", //实缴资本
        approvalDate: data.approvedDate || "-", //"-", //核准日期
        organizationCode: data.organizationCode || "-", //"-", //组织机构代码
        registrationNum: data.registrationNumber || "-", //"-", //工商注册号
        //identificationNum: '',//"", //纳税人识别号
        //enterpriseType: "", //企业类型
        //businessTerm: "", //营业期限
        //taxpayerQualifications: "", //纳税人资质
        //sizeOfPerson: "", //人员规模
        //insuredPersons: "", //参保人数
        //usedName: "", //曾用名
        enName: data.customerEnName || "-", //'', //英文名
        //importAndExportEnterpriseCode: "", //进出口企业代码
        registrationAddress: data.address || "-", //"-", //注册地址
        //businessScope: "", //经营范围
        //taxpayerIdentificationNum: "", //纳税人识别号
        enterpriseType1: data.businessScope || "-", //"-", //企业类型
      };
      if (urlFlag) {
        this.MPsetInformation(["netaddress", data.officialWebsite || ""]);
        this.setWebsite(data.officialWebsite || "");
      }
      this.setAnchorFlag([["QYXX", true]]);
    } catch {
      this.hidden = true;
      this.setAnchorFlag([["QYXX", false]]);
    } finally {
      this.loading = false;
    }

    try {
      const res = null
      /* const res = await axios({
        method: "get",
        url: "/search/Credit/CompanyProfile",
        params: {
          keyword: this.company,
          countryCode: this.$route.query.countryCode
        },
      }); */
      const data = res.data.result;
      this.industryCodes = data.industryCodes || [];
      this.naics = data.naics;
    } catch (error) {
      console.log(error);
    }

    new AnchorInditor(() => {
      this.setCurrent("QYXX");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss">
//样式全局使用
@import "@/firm/scss/mixin.scss";

.enterprise-info-basic-info {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
  color: #1f2d3d;

  .container {
    display: flex;
    min-height: 3em;
    align-items: center;
  }

  .pl {
    padding-left: 1em;
  }

  .border {
    border-style: solid;
    border-color: #d7d7d8;
    border-width: 0;

    &.t {
      border-top-width: 1px;
    }

    &.r {
      border-right-width: 1px;
    }

    &.b {
      border-bottom-width: 1px;
    }

    &.l {
      border-left-width: 1px;
    }
  }
}
</style>
