import router from '@/router'

export function interceptLink(url) {
  const website = (() => {
    if (url.startsWith('http')) {
      return url
    } else {
      return `http://${url}`
    }
  })()
  const u = router.resolve({
    name: 'JumpWeb', query: {
      jumpUrl: website
    }
  }).href
  window.open(u)
  //window.open(`/link.html?url=${website}`, '_blank')
}
