<template>
    <div style="padding: 20px;">
        <el-table :data="synchronizeList" style="width: 100%">
            <el-table-column prop="name" label="公司名称"></el-table-column>
            <el-table-column prop="country" label="国家"></el-table-column>
            <el-table-column prop="webSite" label="网址"></el-table-column>
            <el-table-column prop="call" label="电话"></el-table-column>
            <el-table-column prop="mainIndustryValue" label="行业"></el-table-column>
            <el-table-column prop="companyScaleValue" label="规模"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="belongTo" label="归属"></el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.state=='0'" type="info" size="mini">待同步</el-tag>
                    <el-tag v-else-if="scope.row.state=='1'" type="success" size="mini">同步成功</el-tag>
                    <el-tag v-else-if="scope.row.state=='2'" type="danger" size="mini">同步失败</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 20px 0;display: flex; justify-content: flex-end;" v-if="!iscomplete">
            <el-button type="primary" plain size="small" @click="onSynchronize(0)" :loading="btnLoading">同步公海客户</el-button>
            <el-button type="primary" size="small" @click="onSynchronize(1)" :loading="btnLoading">同步我的客户</el-button>
        </div>
        <div style="margin: 20px 0;display: flex; justify-content: flex-end;" v-else>
            <router-link :to="jumpUrl">
                <el-button type="primary" plain size="small">查看客户</el-button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            synchronizeList: [],
            btnLoading: false,
            iscomplete: false,
            jumpUrl: "",
        };
    },
    methods: {
        //获取同步公司信息
        getList(){
            let key = this.$route.query.key;
            this.$axios.get(`${this.$customerUrl}/Company/GetSynchronizeCompanys?key=${key}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.forEach(ele => {
                        ele.state = "0"; //待同步
                    })
                    this.synchronizeList = data;
                }
            });
        },
        //点击同步客户
        async onSynchronize(type){
            this.btnLoading = true;
            for (const item of this.synchronizeList) {
                if(item.belongTo){
                    item.state = "2";
                    continue;
                }
                item.type = type;
                await this.saveCompany(item);
            }
            this.btnLoading = false;
            this.iscomplete = true;
            if(type == 0){
                this.jumpUrl = "/customerManage/seasCustomer";
            }else{
                this.jumpUrl = "/customerManage/myCustomer";
            }
        },
        async saveCompany(item){
            await this.$axios({
                method: 'POST',
                url: this.$customerUrl + '/Company/Save',
                data: item,
            }).then(res => {
                if(res.isSuccess){
                    item.state = "1"; //同步成功
                }else{
                    item.state = "2"; //同步失败
                }
            })
        },

    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang='scss' scoped>

</style>