<template>
  <!-- 国内国外都有 -->
  <div id="abroad-contact" class="abroad-contact" v-loading="loading" v-show="show">
    <div class="title" ref="title">
      <p>联系人信息</p>
      <feedback position="联系人信息" url="" />
    </div>
    <el-table max-height="500px" :data="d20221122" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
      <el-table-column label="#" width="60px" align="center">
        <template slot-scope="scope">
          <div>{{ scope.$index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column sortable label="姓名" width="212px">
        <template slot-scope="{row}">
          <div style="display: flex;align-items: center;">
            <span>{{ row.contact }}</span>
            <img v-if="row.linkedin" src="../../../e.png" @click="onClickNameLink(row.linkedin)" style="margin-left: 5px;cursor: pointer;" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱地址" />
      <el-table-column prop="position" label="职位" width="212px" />
    </el-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import axios from "@/firm/plugins/axios";
import { AnchorInditor } from "../../../utils";
import { interceptLink } from "@/firm/utils/link";

export default Vue.extend({
  computed: {
    ...mapState(["company"]),
  },
  data() {
    return {
      wbselec: '',
      wbsit: [],
      td: {},
      loading: false,
      tableData: [],
      url: "",
      show: false,
      d20221122: []
    };
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    onClickNameLink(link) {
      interceptLink(link)
    }
  },
  async mounted() {
    try {
      const result = await axios({
        method: "get",
        url: '/search/Credit/EnterpriseLibrary/Contacts', //"/search/Credit/Contact",
        params: {
          companyName: this.company,
          countryCode: this.$route.query.countryCode
          //keyword: this.company,
        },
      });
      console.log({ result: result.data.result, l: 'llllllllllll' })
      this.d20221122 = result.data.result
      if (this.d20221122.length) {
        this.show = true
        this.setAnchorFlag([["LXR", true]]);
      }
    } catch {
      this.d20221122 = [];
    } finally {
      this.loading = false;
    }
    new AnchorInditor(() => {
      this.setCurrent("LXR");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-contact {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}

.smilar-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: var(--themeColor, #3152AF);
  color: #ffffff;
  padding: 9px;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Source Han Sans CN";
  font-weight: 500;
}
</style>
